import { components } from './components';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const appRoutes = () => {
    const user = JSON.parse(localStorage.getItem('login-Data')) || {};
    const isLoggedIn = user.Message === "Success"
    // const isLoggedIn = true

    return [
        {
            path: '*',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.page404 />
                </PublicRoute>
            ),
        },
        {
            path: '/sign-in',
            element: <PublicRoute isLoggedIn={isLoggedIn}>
                <components.signIn />
            </PublicRoute>,
        },
        {
            path: '/otp-login',
            element: <PublicRoute isLoggedIn={isLoggedIn}>
                <components.otpLogin />
            </PublicRoute>,
        },
        {
            path: '/forgot-password',
            element: <PublicRoute isLoggedIn={isLoggedIn}>
                <components.forgotPassowrd />
            </PublicRoute>,
        },
        {
            path: '/change-password',
            element: <PrivateRoute isLoggedIn={isLoggedIn}>
                <components.changePassowrd />
            </PrivateRoute>,
        },
        {
            path: '/venderRegister',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.vendorRegister />
                </PublicRoute>
            ),
        },
        {
            path: '/home',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.dashboard />
                </PrivateRoute>
            ),
        },
        {
            path: '/ProfileSearch/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.vendorManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/ProfileCreate/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.vendorEmpanelment />
                </PrivateRoute>
            ),
        },
        {
            path: '/ProfileUpdate/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.vendorEmpanelment />
                </PrivateRoute>
            ),
        },
        {
            path: '/vendorProfileSummary',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.vendorProfileSummary />
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <components.vendorProfileSummaryIndex />
                },
                {
                    path: "newInvoice",
                    element: <components.createNewInvoice />
                },
                {
                    path: "downloadTdsCertificate",
                    element: <components.downloadTdsCertificate />
                },
            ],
        },
        {
            path: '/vendorMasterAdmin',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.vendorMasterAdmin />
                </PrivateRoute>
            ),
        },
        {
            path: '/invoices/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.invoices />
                </PrivateRoute>
            ),
        },
        {
            path: '/invoice/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.createNewInvoice />
                </PrivateRoute>
            ),
        },
        {
            path: '/downloadTds/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.tdsCertificates />
                </PrivateRoute>
            ),
        },
        {
            path: '/downloadTdsCertificates/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.downloadTdsCertificates />
                </PrivateRoute>
            ),
        },
        {
            path: '/uploadTds/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.uploadTds />
                </PrivateRoute>
            ),
        },
        {
            path: '/ddc/:applicationId/:moduleId/:moduleTypeId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.ddcIndex />
                </PrivateRoute>
            ),
        },
    ];
};

export default appRoutes;
