import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

const Index = () => (
	<>
		{useRoutes(routes())}
	</>
);

export default Index;
