import axios from 'axios';
import API_ROUTE from '../config/appConstants';
import { appEndpoints } from '../services';

const apiGateWay = axios.create({
    baseURL: API_ROUTE,
});
let count = 0
apiGateWay.interceptors.response.use(
    (res) => res,
    async (error) => {
        const { response, config } = error
        if (response.status === 401) {
            if (count > 2) {
                localStorage.clear();
                window.location.href = '/sign-in'; return false
            }
            count = count + 1
            try {
                const ApplicationID = JSON.parse(localStorage.getItem('login-Data'))?.ApplicationID
                const updateTokenResponse = await axios.get("/KareConnectAPI" + appEndpoints.refreshToken({ applicationId: ApplicationID }))
                if (updateTokenResponse?.status === 200) {
                    const RefreshToken = updateTokenResponse.data?.Data?.RefreshToken
                    const JwtToken = updateTokenResponse.data?.Data?.JwtToken

                    localStorage.setItem("login-Data", JSON.stringify({ ApplicationID, ...updateTokenResponse.data }))

                    config.headers["Authorization"] = `Bearer ${JwtToken}`
                    config.headers["Token"] = `Bearer ${RefreshToken}`

                    return apiGateWay.request(config)
                } else {
                    localStorage.clear();
                    window.location.href = '/sign-in';
                }
            } catch (error) {
                localStorage.clear();
                window.location.href = '/sign-in';
            }
        }
    }
);

apiGateWay.interceptors.request.use((config) => {
    try {
        const JwtToken = JSON.parse(localStorage.getItem('login-Data'))?.Data?.JwtToken
        const RefreshToken = JSON.parse(localStorage.getItem('login-Data'))?.Data?.RefreshToken
        const tempConfig = {
            ...config,
            headers: {
                ...config.headers,
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JwtToken}`,
                Token: RefreshToken,
            },
        };
        return tempConfig;
    } catch (error) {
    }
});

export default apiGateWay;

