/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SideBar = (props) => {
  const { isSideBarCollapsed } = props;
  let loginData = localStorage.getItem("login-Data");
  loginData = loginData ? JSON.parse(loginData) : {};
  const isAdmin = loginData?.Data?.IsAdmin;
  const IsDDCEnabled = loginData?.Data?.IsDDCEnabled;
  const IsTDSEnabled = loginData?.Data?.IsTDSEnabled;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [headerStatus, setHeaderStatus] = useState(false);
  useEffect(() => {
    if (isAdmin === false && !loginData?.Data?.IsCreateProfileEnabled) {
      setHeaderStatus(true);
    }
  }, []);

  const adminSideMenuList = [
    {
      slug: "/ProfileSearch/1/5/1",
      label: "Partner Management",
      icon: "person_search",
    },
    {
      slug: "/ProfileCreate/1/5/4?action=create",
      label: "Partner Empanelment",
      icon: "account_box",
    },
    {
      slug: "/ProfileSearch/1/5/1?screen=invmgmnt",
      label: "Invoices",
      icon: "receipt",
    },
    {
      slug: "/ProfileSearch/1/5/1?screen=addressmgmnt",
      label: "Address",
      icon: "receipt",
    },
    {
      slug: "/ProfileSearch/1/5/1?screen=bankmgmnt",
      label: "Bank",
      icon: "receipt",
    },
    {
      slug: "/ProfileSearch/1/5/1?screen=contactmgmnt",
      label: "Contact",
      icon: "receipt",
    },
    {
      slug: "/uploadTds/1/5/9",
      label: "TDS Upload",
      icon: "file_upload",
    },
    {
      slug: "/ProfileSearch/1/5/1?screen=tdsdownload",
      label: "TDS Download",
      icon: "file_download",
    },
    // {
    //     slug: "/ProfileSearch/1/5/1?screen=withholding",
    //     label: "Withholding Management",
    //     icon: "receipt"
    // },
  ];

  const vendorSideMenuTDS = [
    {
      slug: "/downloadTdsCertificates/1/5/9",
      label: "Download TDS",
      icon: "file_download",
    },
  ];
  const vendorSideMenuDDC = [
    {
      slug: "/invoices/1/6/6",
      label: "Invoices",
      icon: "receipt",
    },
    {
      slug: "/ddc/1/18/6",
      label: "Address",
      icon: "receipt",
    },
    {
      slug: "/ddc/1/20/6",
      label: "Bank",
      icon: "receipt",
    },
    {
      slug: "/ddc/1/19/6",
      label: "Contact",
      icon: "receipt",
    },
  ];
  const userType = localStorage.getItem("usertype");
  // let vendorSideMenuList = IsDDCEnabled ? vendorSideMenuDDC : vendorSideMenuTDS
  let vendorSideMenuList = [];
  if (IsDDCEnabled && IsTDSEnabled) {
    vendorSideMenuList = [...vendorSideMenuDDC, ...vendorSideMenuTDS];
  } else if (IsDDCEnabled) {
    vendorSideMenuList = vendorSideMenuDDC;
  } else if (IsTDSEnabled) {
    vendorSideMenuList = vendorSideMenuTDS;
  } else {
    vendorSideMenuList = [];
  }
  const sideMenuList = isAdmin
    ? [...adminSideMenuList]
    : [...vendorSideMenuList];

  const handleMenuClick = (ev, slug) => {
    ev.preventDefault();
    navigate(slug);
  };

  return (
    <nav id="sidebar" className={`${isSideBarCollapsed ? "active" : ""}`}>
      <div className="sidebar-header ">
        <h3
          className="sidebar-brand cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <div className="avatar avatar-xl sidebar-brand-icon h-auto">
            <div className="avatar-title rounded bg-primary">
              <img
                src="/images/Logo-300x300.png"
                className="img-fluid"
                alt="logo"
              />
            </div>
          </div>
          PARTNERCONNECT
        </h3>
        <strong>
          <div className="avatar-title rounded bg-primary">
            <img
              src="/images/Logo-300x300.png"
              className="img-fluid"
              alt="logo"
            />
          </div>
        </strong>
        <ul className="sidebar-menu">
          {sideMenuList.map((menu, id) => {
            return (
              <li className={`sidebar-menu-item`} key={id}>
                {!isSideBarCollapsed ? (
                  <a
                    className="sidebar-menu-button"
                    href=""
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleMenuClick(ev, menu.slug);
                    }}
                    style={{ cursor: headerStatus ? "default" : "pointer" }}
                  >
                    <span
                      className="material-symbols-outlined"
                      title={menu.label}
                    >
                      {menu.icon}
                    </span>
                    <span className="sidebar-menu-text">{menu.label}</span>
                  </a>
                ) : (
                  <strong
                    className="cursor-pointer"
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleMenuClick(ev, menu.slug);
                    }}
                    style={{ cursor: headerStatus ? "default" : "pointer" }}
                    title={menu.label}
                  >
                    <span className="material-symbols-outlined">
                      {menu.icon}
                    </span>
                  </strong>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default React.memo(SideBar);
