import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar/SideBar";
import { addLoader, removeLoader } from "../redux/commonSlice";
import { appEndpoints } from "../services";
import apiGateWay from "../utils/apiGateway";

const PrivateRoute = (props) => {
  const { children, isLoggedIn, isChild = false } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let loginData = localStorage.getItem("login-Data");
  loginData = loginData ? JSON.parse(loginData) : {};
  const isAdmin = loginData?.Data?.IsAdmin;

  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (isAdmin === false) {
      if (!loginData?.Data?.IsProfileCreated) {
        // navigate("/ProfileCreate/1/5/4")
        if (loginData?.Data?.IsCreateProfileEnabled === true) {
          navigate(`/ProfileCreate/1/5/4?action=create`);
        }
        if (loginData?.Data?.IsEditProfileEnabled === true) {
          navigate(`/ProfileCreate/1/5/5?action=edit`);
        }
      }
    }
    // }, [location.pathname, location.search])
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return false;
    }
    if (!localStorage.getItem("moduleTypes")) {
      getAllModuleTypes();
    }
    if (!localStorage.getItem("modules")) {
      getAllModules();
    }
  }, []);

  const getAllModuleTypes = async () => {
    const endpoint = appEndpoints.getModuleTypes({ applicationId: 1 });
    try {
      dispatch(addLoader(endpoint));
      const response = await apiGateWay.get(endpoint);
      if (response?.data?.Message === "Success") {
        localStorage.setItem("moduleTypes", JSON.stringify(response));
      }
      dispatch(removeLoader(endpoint));
    } catch (error) {}
  };

  const getAllModules = async () => {
    const endpoint = appEndpoints.getModules({ applicationId: 1 });
    try {
      dispatch(addLoader(endpoint));
      const response = await apiGateWay.get(endpoint);
      if (response?.data?.Message === "Success") {
        localStorage.setItem("modules", JSON.stringify(response));
      }
      dispatch(removeLoader(endpoint));
    } catch (error) {}
  };

  return isLoggedIn ? (
    <>
      <div
        className="mdk-drawer-layout__content page-content"
        style={{ transform: "translate3d(0px, 0px, 0px)" }}
      >
        {isChild ? null : (
          <Header
            isLoggedIn={isLoggedIn}
            setIsSideBarCollapsed={setIsSideBarCollapsed}
            isSideBarCollapsed={isSideBarCollapsed}
          />
        )}
        {children}
        {isChild ? null : <Footer />}
      </div>
      {isChild ? null : <SideBar isSideBarCollapsed={isSideBarCollapsed} />}
    </>
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateRoute;
