import React from 'react'

const Footer = () => {
	return (
		<div className="bg-white border-top-2 mt-auto">
			<div className="container page__container page-section d-flex flex-column"></div>
		</div>
	)
}

export default Footer