import React from 'react';
import { Navigate } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

const PublicRoute = ({ children, isLoggedIn }) => {
	return isLoggedIn
		? <Navigate to="/home" />
		: <>
			<div className="mdk-drawer-layout__content page-content" style={{ "transform": "translate3d(0px, 0px, 0px)" }}>
				<Header isLoggedIn={isLoggedIn} />
				{children}
				<Footer />
			</div>
		</>;
}

export default PublicRoute;
