import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Loader from './components/common/Loader';
import AppRoutes from './routes/Index';
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import toasterConfig from './utils/notificationUtils';

const App = () => {
    const loaderList = useSelector((state) => state.commonReducer.loaderList);
    return (
        <div
            className="mdk-drawer-layout js-mdk-drawer-layout"
            data-push=""
            data-responsive-width="992px"
        >
            {loaderList.length ? <Loader /> : null}
            <Suspense fallback={<Loader />}>
                <Router>
                    <AppRoutes />
                    <ToastContainer theme="colored" {...toasterConfig} />
                </Router>
            </Suspense>
        </div>
    );
}

export default App;
