export const appEndpoints = {
    authenticateUser: () => "/API/AuthenticateUser",
    verifyOtp: () => "/API/ValidateOTP",
    resendOtp: () => "/API/ResendOTP",
    resetPassword: () => "/api/ResetPassword",
    changePassword: () => "/api/ChangePassword/1",
    refreshToken: ({ applicationId }) => `/api/GetJwtToken/${applicationId}`,
    registration: ({ applicationId }) => `/api/partner/Registration/${applicationId}`,
    uploadFiles: ({ moduleId, moduleTypeId, applicationId }) => `/api/Upload/${applicationId}/${moduleTypeId}/${moduleId}`,
    getFileConfig: ({ moduleId, applicationId }) => `/api/doc/GetFileConfig/${applicationId}/${moduleId}`,
    getProfileData: ({ moduleId, moduleTypeId, applicationId }) => `/api/dashboard/GetProfile/${applicationId}/${moduleTypeId}/${moduleId}`,
    getPartnerFields: ({ moduleId, moduleTypeId, applicationId, pid }) => pid
        ? `/api/partner/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?id=${pid}`
        : `/api/partner/GetFields/${applicationId}/${moduleTypeId}/${moduleId}`,
    savePartnerFields: ({ moduleId, moduleTypeId, applicationId, pid, action }) => {
        if (pid) { 
            return `/api/partner/SaveFieldData/${applicationId}/${moduleTypeId}/${moduleId}?id=${pid}?fieldAction=${action}`
        }
        if (action) {
            return `/api/partner/SaveFieldData/${applicationId}/${moduleTypeId}/${moduleId}?fieldAction=${action}`
        }
        return `/api/partner/SaveFieldData/${applicationId}/${moduleTypeId}/${moduleId}`
    },
    searchData: ({ moduleId, moduleTypeId, applicationId, searchData = {} }) => `/api/partner/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?${new URLSearchParams(searchData).toString()}`,
    getProfilePreview: ({ applicationId, moduleTypeId, moduleId, id }) => {
        return id
            ? `/api/partner/GetProfilePreview/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}`
            : `/api/partner/GetProfilePreview/${applicationId}/${moduleTypeId}/${moduleId}`
    },
    verifyToken: ({ applicationId, token }) => `/api/partner/checksendlinktokenexpired/${applicationId}?token=${token}`,
    getRegistrationField: ({ applicationId, token }) => `/api/partner/getRegistrationField?applicationId=${applicationId}&token=${token}`,
    getUserBySendLinkToken: ({ applicationId, token }) => `/api/partner/GetUserBySendLinkToken/${applicationId}?token=${token}`,
    disableLink: ({ applicationId, userId }) => `/api/partner/DisableLink/${applicationId}/${userId}`,
    resendLink: ({ applicationId, userId }) => `/api/partner/ResendLink/${applicationId}/${userId}`,
    getModuleTypes: ({ applicationId }) => `/api/GetModuleTypes/${applicationId}`,
    getModules: ({ applicationId }) => `/api/GetModules/${applicationId}`,
    getApplicationsList: () => "/api/GetApplications",
    // downloadFileByPath: ({ applicationId }) => `/api/DownloadFile/${applicationId}`,
    downloadFile: ({ moduleId, moduleTypeId, applicationId, id, dbField,isDraft }) => `/api/Download/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}&dbField=${dbField}${isDraft ?`&isDraft=${isDraft}` :""}`,
    DeleteFile: ({ moduleId, moduleTypeId, applicationId, id, dbField }) => `/api/Delete/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}&dbField=${dbField}`,
    DownloadTempFile: ({ moduleId, moduleTypeId, applicationId, pid, fileRef }) => `/api/DownloadTempFile/${applicationId}/${moduleTypeId}/${moduleId}?fileRef=${fileRef}`,
    DeleteTempFile: ({ moduleId, moduleTypeId, applicationId, pid, fileRef }) => `/api/DeleteTempFile/${applicationId}/${moduleTypeId}/${moduleId}?fileRef=${fileRef}`,


    getDDCSearchResult: ({ moduleId, moduleTypeId, applicationId, searchData }, partnerId, invoiceSearchData) => {
        console.log("search data", searchData);
        if (!partnerId) {
            return (invoiceSearchData && Object.keys(invoiceSearchData).length > 0)
                ? `/api/ddc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?${new URLSearchParams(searchData).toString()}&&${new URLSearchParams(invoiceSearchData).toString()}`
                : `/api/ddc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?${new URLSearchParams(searchData).toString()}&&${new URLSearchParams(invoiceSearchData).toString()}`
        } else {
            return (invoiceSearchData && Object.keys(invoiceSearchData).length > 0)
                ? `/api/ddc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&${new URLSearchParams(searchData).toString()}&${new URLSearchParams(invoiceSearchData).toString()}`
                : `/api/ddc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&${new URLSearchParams(searchData).toString()}&${new URLSearchParams(invoiceSearchData).toString()}`
        }
    },
    getDDCSearchForm: ({ moduleId, moduleTypeId, applicationId }, invoiceId, partnerId) => {
        if (partnerId) {
            return invoiceId
                ? `/api/ddc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?id=${invoiceId}&PartnerId=${partnerId}`
                : `/api/ddc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?PartnerId=${partnerId}`
        } else {
            return invoiceId
                ? `/api/ddc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?id=${invoiceId}`
                : `/api/ddc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}`
        }
    },
    getDOCFields: ({ moduleId, moduleTypeId, applicationId }, partnerId) => {
        if (partnerId) {
            return `/api/doc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}`
        } else {
            return `/api/doc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}`
        }
    },
    saveDDCData: ({ moduleId, moduleTypeId, applicationId }, action) => `/api/ddc/SaveFieldData/${applicationId}/${moduleTypeId}/${moduleId}?fieldAction=${action}`,
    saveDOCData: ({ moduleId, moduleTypeId, applicationId }, action) => `/api/doc/SaveFieldData/${moduleId}/${moduleTypeId}/${applicationId}?fieldAction=${action}`,
    // GetDDCFieldsPreview: ({ moduleId, moduleTypeId, applicationId }, id, partnerId) => `/api/ddc/GetPreview/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}&partnerId=${partnerId}`,
    GetDDCFieldsPreview: ({ moduleId, moduleTypeId, applicationId }, id, partnerId) => {
        if (partnerId == undefined) {
            return `/api/ddc/GetPreview/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}`
        }
        else {
            return `/api/ddc/GetPreview/${applicationId}/${moduleTypeId}/${moduleId}?id=${id}&partnerId=${partnerId}`
        }

    },
    getDocFilrValidateConfig: ({ moduleId, moduleTypeId, applicationId }) => `/api/doc/GetFileConfig/1/5`,
    getDoCSearchForm: ({ moduleId, moduleTypeId, applicationId }, invoiceId) => {
        return invoiceId
            ? `/api/doc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}?id=${invoiceId}`
            : `/api/doc/GetFields/${applicationId}/${moduleTypeId}/${moduleId}`
    },
    getDoCSearchResult: ({ moduleId, moduleTypeId, applicationId, searchData }, partnerId, invoiceSearchData) => {
        console.log("search data", searchData);
        if (!partnerId) {
            return (invoiceSearchData && Object.keys(invoiceSearchData).length > 0)
                ? `/api/doc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?${new URLSearchParams(invoiceSearchData).toString()}&&${new URLSearchParams(searchData).toString()}`
                : `/api/doc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}&&${new URLSearchParams(searchData).toString()}`
        } else {
            return (invoiceSearchData && Object.keys(invoiceSearchData).length > 0)
                ? `/api/doc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&&${new URLSearchParams(invoiceSearchData).toString()}&&${new URLSearchParams(searchData).toString()}`
                : `/api/doc/SearchGrid/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&&${new URLSearchParams(searchData).toString()}`
        }
    },
    getCascadedFieldValues: ({ moduleId, moduleTypeId, applicationId, field, value }) => {
        if (value) {
            return `/api/GetCascadingData/${applicationId}/${moduleTypeId}/${moduleId}?field=${field}&&value=${value}`
        }
        return `/api/GetCascadingData/${applicationId}/${moduleTypeId}/${moduleId}?field=${field}`
    },

    getDDCLookupFieldFieldValues: ({ moduleId, moduleTypeId, applicationId, field, value, partnerId, search }) => {
        if (partnerId == undefined) {
            return `/api/DDC/DDCLookup/${applicationId}/${moduleTypeId}/${moduleId}?dbfield=${field}&search=${search}`
        }
        else if (value) {
            return `/api/DDC/DDCLookup/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&dbfield=${field}&&value=${value}`
        }
        return `/api/DDC/DDCLookup/${applicationId}/${moduleTypeId}/${moduleId}?partnerId=${partnerId}&dbfield=${field}&search=${search}`
    }
}