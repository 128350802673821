import React from 'react';

export const components = {
    page404: React.lazy(() => import('../components/common/Page404')),
    signIn: React.lazy(() => import('../components/auth/SignIn')),
    otpLogin: React.lazy(() => import('../components/auth/OtpLogin')),
    forgotPassowrd: React.lazy(() => import('../components/auth/ForgotPassword')),
    changePassowrd: React.lazy(() => import('../components/auth/ChangePassword')),
    dashboard: React.lazy(() => import('../components/Dashboard/Index')),
    vendorManagement: React.lazy(() => import('../components/vendorManagement/Index')),
    vendorRegister: React.lazy(() => import('../components/vendorManagement/VendorRegister')),
    vendorEmpanelment: React.lazy(() => import('../components/vendorEmpanelment/Index2')),
    vendorProfileSummary: React.lazy(() => import('../components/VendorProfileSummary/Index')),
    vendorProfileSummaryIndex: React.lazy(() => import('../components/VendorProfileSummary/ProfileSummaryIndex')),
    vendorMasterAdmin: React.lazy(() => import('../components/VendorMasterAdmin/Index')),
    ddcIndex: React.lazy(() => import('../components/DDC/Index')),
    invoices: React.lazy(() => import('../components/Invoice/Index')),
    uploadTds: React.lazy(() => import('../components/UploadTdsCertificates/Index')),
    tdsCertificates: React.lazy(() => import('../components/Tds/Index')),
    createNewInvoice: React.lazy(() => import('../components/Invoice/CreateNewInvoice/Index')),
    downloadTdsCertificate: React.lazy(() => import('../components/VendorProfileSummary/DownloadTdsCertificate/Index')),
    downloadTdsCertificates: React.lazy(() => import('../components/DOC/Index')),
};