import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	fileFieldList: []
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateFileFieldList: (state, action) => {
			state.fileFieldList = action.payload
		}
	},
})

export const { updateFileFieldList } = userSlice.actions

export default userSlice.reducer