/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const Header = ({ isLoggedIn, isSideBarCollapsed, setIsSideBarCollapsed }) => {

    const navigate = useNavigate();
    const location = useLocation();

    let loginData = localStorage.getItem("login-Data")
    loginData = loginData ? JSON.parse(loginData) : {}
    const isAdmin = loginData?.Data?.IsAdmin
    const IsProfileCreated = loginData?.Data?.IsProfileCreated
    const IsDDCEnabled = loginData?.Data?.IsDDCEnabled
    const IsDocEnabled = loginData?.Data?.IsDocEnabled

    const UserName = loginData?.UserName

    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false)
    const [headerStatus, setHeaderStatus] = useState(false)
    useEffect(() => {

        //if (isAdmin === true && IsProfileCreated) {
        if (isAdmin) {
            setHeaderStatus(false)
        }
        else if (IsDDCEnabled || IsDocEnabled) {
            setHeaderStatus(false)
        } else {
            setHeaderStatus(true)
        }
    }, [])

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsProfileDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    const toggleDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    }
    const showReviewMessage = location.pathname === '/home' && headerStatus;

    return (
        <>
            <div
                className="navbar navbar-expand pr-0 navbar-light border-bottom-2 d-flex justify-content-between"
                id="default-navbar"
                data-primary=""
            >
                {
                    isLoggedIn
                        ? <>
                            <button type="button" id="sidebarCollapse" className="btn btn-light" onClick={() => setIsSideBarCollapsed(!isSideBarCollapsed)}>
                                <i className="fas fa-align-left" />
                            </button>
                            <div className="flex" />
                            <div className="nav navbar-nav flex-nowrap d-flex mr-16pt">
                                {/* <div className="font-btn">
									<button
										type="button"
										value="increase"
										className="btn btn-light increaseFont"
									>
										A +
									</button>
									<button
										type="button"
										value="decrease"
										className="btn btn-light decreaseFont"
									>
										A -
									</button>
								</div> */}
                                {showReviewMessage &&
                                    <div
                                        style={{
                                            color: "white",
                                            display: showReviewMessage ? "flex" : "none",
                                            alignItems: "end",
                                            paddingBottom: "1rem",
                                            fontSize: "0.9rem"
                                        }}
                                    >
                                        Your profile is under review. Kindly wait for approval of your application.
                                    </div>
                                }
                                <div
                                    className="nav-item ml-16pt dropdown dropdown-notifications dropdown-xs-down-full"
                                    data-toggle="tooltip"
                                    data-title="Notifications"
                                    data-placement="bottom"
                                    data-boundary="window"
                                >
                                    <h3 className='mb-0 mt-0' style={{ color: "white" }}>Welcome {UserName}</h3>
                                </div>
                                {/* <div
									className="nav-item ml-16pt dropdown dropdown-notifications dropdown-xs-down-full"
									data-toggle="tooltip"
									data-title="Notifications"
									data-placement="bottom"
									data-boundary="window"
								>
									<button
										className="nav-link btn-flush dropdown-toggle"
										type="button"
										data-toggle="dropdown"
										data-caret="false"
									>
										<i className="material-icons">notifications_none</i>
										<span className="badge badge-notifications badge-accent">2</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<div data-perfect-scrollbar="" className="position-relative">
											<div className="dropdown-header">
												<strong>System notifications</strong>
											</div>
											<div className="list-group list-group-flush mb-0">
												<a
													href="##"
													className="list-group-item list-group-item-action unread"
												>
													<span className="d-flex align-items-center mb-1">
														<small className="text-black-50">3 minutes ago</small>
														<span className="ml-auto unread-indicator bg-accent" />
													</span>
													<span className="d-flex">
														<span className="avatar avatar-xs mr-2">
															<span className="avatar-title rounded-circle bg-light">
																<i className="material-icons font-size-16pt text-accent">
																	account_circle
																</i>
															</span>
														</span>
														<span className="flex d-flex flex-column">
															<span className="text-black-70">
																Your profile information has not been synced correctly.
															</span>
														</span>
													</span>
												</a>
												<a
													href="##"
													className="list-group-item list-group-item-action"
												>
													<span className="d-flex align-items-center mb-1">
														<small className="text-black-50">5 hours ago</small>
													</span>
													<span className="d-flex">
														<span className="avatar avatar-xs mr-2">
															<span className="avatar-title rounded-circle bg-light">
																<i className="material-icons font-size-16pt text-primary">
																	group_add
																</i>
															</span>
														</span>
														<span className="flex d-flex flex-column">
															<strong className="text-black-100">Adrian. D</strong>
															<span className="text-black-70">
																Wants to join your private group.
															</span>
														</span>
													</span>
												</a>
												<a
													href="##"
													className="list-group-item list-group-item-action"
												>
													<span className="d-flex align-items-center mb-1">
														<small className="text-black-50">1 day ago</small>
													</span>
													<span className="d-flex">
														<span className="avatar avatar-xs mr-2">
															<span className="avatar-title rounded-circle bg-light">
																<i className="material-icons font-size-16pt text-warning">
																	storage
																</i>
															</span>
														</span>
														<span className="flex d-flex flex-column">
															<span className="text-black-70">
																Your deploy was successful.
															</span>
														</span>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div> */}
                                <div className={`nav-item dropdown ${isProfileDropdownOpen ? "show" : ""}`} ref={dropdownRef}>
                                    <a
                                        href="#"
                                        className="nav-link d-flex align-items-center dropdown-toggle"
                                        data-toggle="dropdown"
                                        data-caret="false"
                                        onClick={toggleDropdown}
                                    >
                                        <span className="avatar avatar-sm mr-8pt2">
                                            <span className="avatar-title rounded-circle bg">
                                                <i className="material-icons">account_box</i>
                                            </span>
                                        </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        {
                                            !isAdmin
                                                ? <a
                                                    className="dropdown-item cursor-pointer"
                                                    onClick={() => {
                                                        if (!IsProfileCreated) {
                                                            if (loginData?.Data?.IsCreateProfileEnabled === true) {
                                                                navigate(`/ProfileUpdate/1/5/4?action=create`)
                                                            }
                                                            if (loginData?.Data?.IsEditProfileEnabled === true) {
                                                                navigate(`/ProfileUpdate/1/5/5?action=edit`)
                                                            }
                                                        } else if (loginData?.Data?.IsEditProfileEnabled === true) {
                                                            navigate(`/ProfileUpdate/1/5/5?action=edit`)
                                                        } else {
                                                            navigate(`/ProfileCreate/1/5/5?action=view`)
                                                        }
                                                    }}>
                                                    Edit Profile
                                                </a>
                                                : null
                                        }
                                        {IsProfileCreated && (
                                            <a
                                                className="dropdown-item cursor-pointer"
                                                onClick={() => {
                                                    window.location.href = "/change-password"
                                                }}
                                            >
                                                Change Password
                                            </a>
                                        )}
                                        <a
                                            className="dropdown-item cursor-pointer"
                                            onClick={() => {
                                                localStorage.clear()
                                                window.location.href = "/sign-in"
                                            }}
                                        >
                                            Logout
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="nav-item ">
                            <span className="avatar avatar-sm mr-8pt2">
                                <span className="avatar-title rounded bg-primary"><img
                                    src="/images/Logo-300x300.png" className="img-fluid" alt="logo" /></span>
                            </span>
                        </div>



                }
                <div className="nav-item mr-2">
                    <span className="
                    ">
                        <img
                            src="/images/azim-logo.png" className="" alt="logo" width={150} />
                    </span>
                </div>
            </div>

            <div className="navbar1 navbar-expand pr-0 navbar-light border-bottom-22 " id="default-navbar1" data-primary />



        </>

    )
}

export default React.memo(Header)